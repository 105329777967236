.gpay-button {
  box-shadow: 0 0 0 !important;
}
.gpay-button.black.pay.en {
  background-size: 50px;
  background-image: url(../assets/google-pay.png) !important;
  border-radius: 40px;
  background-position-y: 13px;
  width: 100% !important;
  height: 45px;
}

.amount-popup-overlay .gpay-button.black.pay.en {
  background-size: 50px;
  animation: zoom-in-gpay 1s ease;
  border-radius: 40px;
  width: 100% !important;
}

.wrap-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.amount-popup {
  animation: zoom-in 1s ease;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.3, 0.3);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes zoom-in-gpay {
  0% {
    transform: scale(0.7, 0.7);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Public Sans", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: none;
}

.background-wrapper {
  position: relative;
  /* height: 70vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

img.background-image {
  width: 100%;
  display: block;
  height: 100%;
  position: relative;
}

.boxwrapper {
  position: absolute;
  width: 34rem;
  /* left: 50%;
  transform: translate(-50%, 70px); */
  border-radius: 8px;
  padding: 1.2em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.cont2 {
  position: absolute;
  background: #fff;
  width: 34rem;
  /* left: 50%;
  transform: translate(-50%, 70px); */
  border-radius: 8px;
  padding: 1.2em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.logo-div {
  text-align: center;
  position: relative;
  margin: 15px auto -79px;
  background: #f6931e;
  width: 300px;
  padding: 15px 20px 1px;
  /* overflow: hidden; */
  border-radius: 0px 0px 20px 20px;
  z-index: 2;
}

.logo-div::before {
  position: absolute;
  content: "";
  background: conic-gradient(at 50% 50%,
      transparent 135deg,
      #d4790d 0,
      #d4790d 225deg,
      transparent 0);
  width: 20px;
  height: 20px;
  left: -10px;
  top: 4px;
  z-index: -1;
  transform: rotateZ(135deg);
}

.logo-div::after {
  position: absolute;
  content: "";
  background: conic-gradient(at 50% 50%,
      transparent 135deg,
      #d4790d 0,
      #d4790d 225deg,
      transparent 0);
  width: 20px;
  height: 20px;
  right: -10px;
  top: 4px;
  z-index: -1;
  transform: rotateZ(-135deg);
}

.logo-box {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background: #fff;
  left: 41%;
  text-align: center;
  padding: 10px 10px;
  transform: translate(-10%, -80px);
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  z-index: 999999;
  top: 30px;
}

img.logo-image {
  width: 100%;
  display: block;
  position: relative;
  top: 24px;
}

h5.company-name {
  position: relative;
  font-size: 14px;
  margin: 0 0 0 0;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  min-height: 16.5px;
}

p.company-address {
  font-size: 13px;
  color: #fce9c3;
  margin: 4px 0 15px 0;
  text-transform: uppercase;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  min-height: 15px;
}

.clear {
  clear: both;
}

.oreder-overview {
  position: relative;
}

.oreder-overview h4 {
  margin: 0 0 15px 0;
  padding: 10px 0 12px;
  border-bottom: 1px solid #ccc;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.oreder-overview h4 span {
  text-align: end;
  float: right;
  font-size: 1.3rem;
  color: #303030;
}

.oreder-overview h4 span strong {
  font-size: 1rem;
  position: relative;
  top: -9px;
  color: #7a7a7a;
  font-weight: 600;
}

.credit-icon {
  position: absolute;
  left: 10px;
  top: 6px;
  width: 1.6em;
}

img.card-icons {
  position: relative;
  width: 30px;
  margin: 0 10px -10px 0;
}

.alsolute-cards {
  position: absolute;
  right: -3px;
  bottom: 55px;
}

.action-btn {
  position: relative;
  margin: 10px 0;
}

.action-btn button {
  border-radius: 4px;
  cursor: pointer;
}

.poweredby {
  text-align: center;
  margin: 10px auto -10px;
}

.poweredby p {
  font-size: 13px;
  color: #ababab;
  letter-spacing: 0;
  padding: 0;
  margin: 0 0 0 0;
}

img.payment-options-logo {
  display: inline-block;
  width: 6rem;
  vertical-align: middle;
  margin: 0 0 0 3px;
}

.greentick {
  fill: rgb(1, 177, 78);
}

.errors {
  position: absolute;
  font-size: 11px;
  color: red;
  bottom: -16px;
}

/* .errors-class svg {
  width: 11px;
  position: relative;
  top: 8px;
  fill: #ff4868;
  margin-right: 3px;
} */
span.errors-class input,
span.errors-class select {
  border-color: red !important;
}

svg.removebtn {
  fill: red;
  width: 10px;
  height: 10px;
  right: -3px;
  position: relative;
  top: 0px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.loader-container {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  left: 0;
  display: flex;
  z-index: 999999999;
  text-align: center;
  border-radius: 8px;
}

img.loader-gif {
  position: relative;
  /* transform: translate(10px, 50%); */
  width: 120px;
}

.toast-snackbar .MuiPaper-root {
  background-color: #e2445c;
}

.toast-snackbar {
  z-index: 9999999;
}

/* ============== payment message css ====================*/
.payment-container {
  /* position: absolute; */
  /* background: #fafcff; */
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 100%;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.combo-cont {
  /* position: absolute; */
  background: #fff;
  width: 100%;
  /* left: 50%;
  transform: translate(-50%, 70px); */
  border-radius: 30px;
  padding: 1.2em;
  border: 1px solid #eae8e8;
  box-shadow: 0px 20px 30px 0px #0000000d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.payment-box {
  width: 100%;
  background: #fff;
  position: relative;
  margin: 0;
  left: 0;
  top: 0rem;
  border-radius: 1em;
  padding: 3rem;
}

.payment-box.failed-box {
  top: 0rem;
}

.payment-img-container {
  display: block;
  text-align: center;
}

img.payment-img-icon {
  width: 4rem;
  display: inline-block;
  margin: 0 15px 4px 0;
  vertical-align: middle;
}

h4.payment-status {
  text-align: center;
  font-weight: 600;
  font-size: 1.7rem;
  margin: 0 0 10px 0;
  display: inline-block;
}

.payment-success-green {
  color: #32bea6;
}

.payment-failed-red {
  color: #e04f5f;
}

p.transaction-id-no {
  text-align: center;
  color: #656464;
  font-size: 1.12rem;
  letter-spacing: 0.05rem;
}

hr.dotted {
  border: 1px dashed #999;
  background: transparent;
  margin: 2rem 0 2rem 0;
}

.pay-info {
  position: relative;
}

.pay-info h5 {
  color: #656464;
  font-size: 1rem;
  display: block;
  margin: 0 0 13px 0;
  font-weight: 600;
}

.pay-info h5 span {
  text-align: right;
  display: inline-block;
  font-weight: 400;
  float: right;
  width: 49%;
}

.pay-info h5:last-child {
  margin-bottom: 0;
}

a.back-home {
  background: transparent;
  text-decoration: none;
  text-align: center;
  display: block;
  font-size: 0.9rem;
  background: #dedede;
  background: linear-gradient(92.03deg, #fcc954 0%, #e56523 100%);
  border-radius: 40px;
  padding: 10px 24px;
  color: #f3f3f3;
  margin: 10px auto 0;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
}

/*============= product details =================*/
/* .product-pay-details {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1px solid #ccc;
  margin: -10px 0 10px 0px;
  border-radius: 6px;
  box-shadow: rgb(149 157 165 / 20%) 0px 2px 18px;
} */
.product-pay-details {
  position: absolute;
  right: -275px;
  top: 10px;
  height: 100%;
  width: 280px;
  background: #fff;
  display: block;
  padding: 10px;
  /* border-left: 1px solid #f4f4f4; */
  margin: -10px 0 10px 0px;
  border-radius: 0px 6px 6px 0px;
  box-shadow: rgb(149 157 165 / 20%) 0px 2px 18px;
}

.overlay-wrap {
  height: 97%;
  overflow: hidden;
  overflow-y: auto;
  margin: 6px 0 14px;
  padding: 2px 5px;
}

.overlay-wrap::-webkit-scrollbar {
  width: 2px;
}

.overlay-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.overlay-wrap::-webkit-scrollbar-thumb {
  background-color: #06304e;
  outline: 1px solid slategrey;
}

img.pro-image {
  display: block;
  width: 54px;
  height: 54px;
  border-radius: 4px;
}

.product-details {
  width: 85%;
  display: inline-block;
  position: relative;
  top: 7px;
}

/* .product-details h5 {
  font-size: .8em;
  font-weight: 500;
  margin: 0 30px 0 0;
} */
.product-details h5 {
  font-size: 0.8em;
  font-weight: 600;
  line-height: 15px;
  margin: 2px 0px 5px 0;
}

h5.product-name {
  width: 72%;
}

/* h6.product-quantity {
  min-width: 15%;
} */
h6.product-quantity {
  min-width: 15%;
  position: relative;
  margin: 0;
  padding: 0;
  font-weight: 500;
  display: inline-block;
}

h6.product-price {
  min-width: 15%;
  margin: 0 30px 0 30px;
  padding: 0;
  position: relative;
  right: 0;
  top: 0;
  font-size: 0.85rem;
  display: inline-block;
}

.multiply-price {
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
  font-size: 0.85rem;
}

h6.product-price.p-total-price {
  position: absolute;
  right: 0;
  top: 19px;
  margin: 0;
  font-size: 0.95rem;
  text-align: right;
}

button.pop-up-remove-btn {
  position: absolute;
  right: -13px;
  z-index: 999;
  top: -15px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px 4px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 40px;
  display: none;
}

button.pop-up-remove-btn svg.removebtn {
  right: 0;
}

button.open-product-deatail {
  position: absolute;
  right: -1px;
  z-index: 8;
  top: -44px;
  background: #fff;
  border: 1px solid #456387;
  padding: 9px 0px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  border-radius: 40px;
  display: none;
}

button.open-product-deatail svg {
  fill: #456387;
}

button.open-product-deatail badge {
  top: -7px;
  position: absolute;
  background: #456387;
  color: #ffff;
  width: 20px;
  height: 20px;
  border-radius: 24px;
  padding: 3px 4px;
  right: -9px;
  font-size: 0.75rem;
  font-weight: 600;
}

.jpyamount {
  background-image: url("./components/assets/jpy-amount.png");
  background-size: 21px;
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 7px;
}

.sgdamount {
  background-image: url("./components/assets/sgd-amount.png");
  background-size: 21px;
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 7px;
}

.usdamount {
  background-image: url("./components/assets/usd-amount.png");
  background-size: 21px;
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 7px;
}

.hkdamount {
  background-image: url("./components/assets/hkd-amount.png");
  background-size: 21px;
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 7px;
}

.phpamount {
  background-image: url("./components/assets/payment/php.png");
  background-size: 21px;
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 7px;
}

/*====================26-04-2022=============*/
p.text-center {
  text-align: center;
  margin: 0;
}

.tcenter {
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 10px 0;
  position: relative;
}

a.download-receipt {
  display: block;
  color: #2ebfa8;
}

img.download-img-icon {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  margin: 0 2px 0 0;
}

a.download-receipt svg {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  fill: #2ebfa8;
}

form.payment-card-form h5 {
  margin: 0 0 8px 0px;
  padding: 0;
  font-weight: 500;
  text-transform: uppercase;
  color: #3a3a3a;
}

.select-options {
  font-size: 15px;
  background-color: #ffffff;
  padding: 10px;
}

input#custom-country-select {
  width: 100%;
  padding: 5px 45px 4px 15px;
  color: black;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 36px;
}

svg.dropdown-arrow {
  position: absolute;
  fill: #ccc;
  width: 12px;
  right: 11px;
  top: 6px;
}

.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root {
  position: relative;
  top: 0px;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 7px 4px 7px 6px;
  height: 0.3em;
  font-size: 14px;
  color: #333;
}

.css-1s2u09g-control {
  font-size: 14px;
  position: relative;
  top: 2px;
  height: 36px;
  min-height: 36px;
}

.payment-container.error-container {
  z-index: 9;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 70%;
  border-radius: 30px !important;
}

.error-box {
  top: 0;
  padding: 3rem 2rem;
}

.payment-img-container h2 {
  font-size: 80px;
  margin: 0 auto 20px;
}

input::-webkit-input-placeholder {
  /* Edge */
  color: #9b9b9b;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9b9b9b;
}

input::placeholder {
  color: #9b9b9b;
}

.select-country .css-1s2u09g-control {
  border-radius: 5px;
  border-color: #d9d9d9;
  font-weight: inherit;
  min-height: 36px !important;
}

.css-14el2xx-placeholder {
  color: #9b9b9b;
}

.errors-class .css-1s2u09g-control {
  border-color: red;
}

/*============= 23-06-22 ============*/
.countdown-timer {
  z-index: 99999;
  position: relative;
  top: 160px;
  color: #446487;
  font-size: 1rem;
}

.countdown-timer strong {
  font-size: 1.2rem;
  display: inline-block;
  margin: 0 0 0 2px;
}

h6.payment-status-sub-heading {
  font-size: 1.2rem;
  margin: 12px 0 30px;
  padding: 0 30px;
}

/* ============== 29-07-22 ========================= */
.wrapper-div {
  position: absolute;
  padding: 0 0%;
  width: 1060px !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
}

.form-list-wrap .boxwrapper {
  position: relative;
  width: 100%;
  padding: 1.7em 2rem 1.2rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-height: 472px;
  box-shadow: rgb(149 157 165 / 20%) 0px 0px 0px;
}

.form-list-without-wrap .boxwrapper {
  position: relative;
  width: 100%;
  padding: 1.2em 0rem 1.2rem;
  min-height: 444px;
  -webkit-box-shadow: rgb(149 157 165 / 20%) 0px 0px 0px;
  box-shadow: rgb(149 157 165 / 20%) 0px 0px 0px;
}

.wrapper-div .MuiGrid-grid-xs-5,
.wrapper-div .MuiGrid-grid-xs-7,
.wrapper-div .MuiGrid-grid-xs-6 {
  padding: 0 !important;
}

.product-list-wrap {
  position: relative;
  background: #ffffff;
  margin: 28px 0 30px !important;
  /* min-height: 472px; */
  padding: 0.8rem 2.5rem 1rem !important;
  border-radius: 30px !important;
  top: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 20px 30px 0px #0000000d;
  z-index: 1;
  display: flex;
  width: 36rem;
}

.form-list-wrap .boxwrapper .oreder-overview {
  display: none;
}

.form-list-without-wrap {
  position: relative;
  top: 3px;
}

.product-lists {
  position: relative;
  height: auto;
  padding: 1.7em 0 0rem 0;
  width: 100%;
}

.product-total-amount {
  position: relative;
  margin: 0;
  padding: 0 0;
}

.product-total-amount h4 span {
  display: block;
  margin: 13px 0 0 0;
  font-weight: 500;
  font-size: 1.4rem;
}

.product-total-amount h4 {
  display: block;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.product-total-amount h4 span strong {
  font-size: 0.9rem;
  position: relative;
  top: -9px;
  color: #7a7a7a;
  font-weight: 600;
}

.wrapper-div .logo-box {
  left: 45%;
  /* -webkit-transform: translate(-9%, -255px); */
  transform: translate(-9%, -245);
}

.form-list-wrap .boxwrapper .logo-div {
  margin: 20px 0 0 0;
}

.products-listings {
  position: relative;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  margin: 0 0 0 -3px;
}

.products-listings .product-box {
  padding: 0;
  border-radius: 0;
}

.products-listings .product-box {
  padding: 0;
  border-radius: 0;
  /* border-bottom: 1px solid #e2dfdf; */
  margin: 5px 0px 12px 0;
  position: relative;
}

/* .products-listings .product-box:last-child {
  border-bottom: 1px solid #dcdcdc;
} */

.product-image {
  width: 11%;
  height: auto;
  position: relative;
  padding: 0px;
  vertical-align: top;
  margin: 3px 10px 8px 0;
  -webkit-box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  display: inline-block;
}

.products-listings::-webkit-scrollbar {
  width: 3px;
}

.products-listings::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.products-listings::-webkit-scrollbar-thumb {
  background-color: #15344a;
  outline: 1px solid slategrey;
}

input.form-control.quantity-control {
  width: 50px;
  position: relative;
  margin: 0 5px 0 5px;
  border-radius: 5px;
  border: 0px solid #d9d9d9;
  padding: 3px 12px;
}

input.form-control.quantity-control:focus {
  outline: 0;
}

.payment-quantitys {
  display: inline-block;
  color: #7a7a7a;
  font-weight: 600;
  font-size: 0.85rem;
}

.form-list-wrap .boxwrapper form.payment-card-form {
  padding-top: 17px;
}

.language-select {
  display: block;
  width: 100%;
  position: relative;
  margin: 0px 0 0 0;
  text-align: right;
}

select.language-selection {
  position: relative;
  border: 0;
  background: transparent;
  cursor: pointer;
}

select.language-selectionoption {
  cursor: pointer;
}

select.language-selection:hover,
select.language-selection:focus {
  outline: 0;
}

.footer-wrap {
  position: fixed;
  bottom: 5px;
  width: 1060px;
  background: #fff;
  padding: 5px 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  z-index: 1;
}

.show-wrap-length {
  position: fixed;
  bottom: 5px;
  width: 33em;
  background: #fff;
  padding: 5px 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.footer-left img.logo-image {
  width: 60px;
  top: 0;
}

img.flag-img {
  width: 24px;
  margin: 0;
}

.language-select .MuiSelect-select {
  min-height: auto;
  padding: 6px 12px 1px;
}

.language-select fieldset {
  border: 0;
}

.MuiSelect-select.MuiSelect-outlined span.language-label {
  display: none;
}

span.language-label {
  display: inline-block;
  margin-left: 5px;
}

.select-country .css-qc6sy-singleValue {
  padding: 0px 0 0 5px;
  color: #9b9b9b;
  position: relative;
  top: -2px;
}

.show-wrap-length.error-footer {
  justify-content: space-between;
  z-index: 10;
  width: 34em !important;
}

.twocolumn {
  position: relative;
}

.cardcolumn {
  position: relative;
}

.twocolumn .inputgroup {
  width: 48%;
  padding: 0 0;
  margin: 0 1% 20px;
  position: relative;
  display: inline-block;
}

.cardcolumn .inputgroup {
  width: 48%;
  padding: 0 0;
  margin: 0 2% 12px 0;
  position: relative;
  display: inline-block;
}

.cardcolumn .inputgroup:nth-child(2) {
  width: 100% !important;
}

.cardcolumn .inputgroup:nth-child(4),
.cardcolumn .inputgroup:nth-child(5),
.cardcolumn .inputgroup:nth-child(6),
.cardcolumn .inputgroup:nth-child(10),
.cardcolumn .inputgroup:nth-child(11) {
  width: 48% !important;
}

.cardcolumn .inputgroup:nth-child(5),
.cardcolumn .inputgroup:nth-child(7),
.cardcolumn .inputgroup:nth-child(8),
.cardcolumn .inputgroup:nth-child(11) {
  margin-right: 0;
  margin-left: 2%;
}

.cardcolumn .inputgroup:nth-child(7),
.cardcolumn .inputgroup:nth-child(8) {
  width: 23% !important;
}

/* .cardcolumn .inputgroup:nth-child(7) {
  width: 28% !important;
} */

.cardcolumn .formcontrol {
  width: 100%;
  padding: 5px 30px 4px 15px;
  color: black;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 36px;
}

.cardcolumn .inputgroup:nth-child(2) .formcontrol,
.cardcolumn .inputgroup:nth-child(6) .formcontrol,
.cardcolumn .inputgroup:nth-child(7) .formcontrol {
  padding: 5px 30px 4px 40px;
}

.inputcheck {
  position: absolute;
  right: 0;
  top: 36px;
  /* border-left: 1px solid #ccc; */
  padding: 2px 8px;
  width: 36px;
}

.inputcheck svg {
  position: relative;
  top: 2px;
}

.submitbtn {
  padding: 12px 20px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  background: #f6931e;
  color: #fff;
  border-radius: 50px;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.3px;
  transition: all 0.7s ease 0s;
  width: 49%;
  margin: 0 0% 0 1%;
  border: 0;
  cursor: pointer;
  height: 45px;
}

.submitbtn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.cancelbtn {
  padding: 12px 20px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  background: #e5e5e5;
  color: #606060;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.3px;
  transition: all 0.7s ease 0s;
  width: 49%;
  margin: 0 1% 0 0%;
  border: 0;
  border-radius: 50px !important;
  cursor: pointer;
  height: 45px;
}

.submitbtn:hover,
.submitbtn:focus {
  background: #494747;
  /* background: -webkit-linear-gradient(to right, #667db6, #667db6, #0082c8, #0082c8);
  background: linear-gradient(to right, #667db6, #667db6, #0082c8, #0082c8); */
}

span.errors-class .month-year-select {
  border: 1px solid red;
  border-radius: 50px;
}

span.errors-class .month-year-input {
  border: 1px solid red;
  border-radius: 50px;
}

.inputgroup .month-year-select input {
  height: 35px !important;
}

span.errors-class #country div:nth-child(3) {
  border-color: red;
}

.cancelbtn:hover,
.cancelbtn:focus {
  background: rgb(203, 199, 199);
}

.formLabel {
  margin: 0 0 8px 0px;
  padding: 0;
  font-weight: 500;
  text-transform: uppercase;
  color: #3a3a3a;
}

.MuiGrid-grid-xs-12.FormFieldGrid-grid {
  display: none;
}

/* goole-pay-button */
.google-pay-button {
  background: #000;
  width: 49%;
  border-radius: 40px;
  border: 0;
  height: 43px;
  color: #fff;
  /* font-size: 16px; */
  cursor: pointer;
  margin: 0 0 0 1%;
}
.pay-pay-button {
  text-align: center;
  background: #000;
  border-radius: 40px;
  border: 0;
  height: 43px;
  color: #fff;
  cursor: pointer;
  margin: 0 0 0 1%;
}
.pay-pay-logo {
  margin-top: 2%;
  position: relative;
  align-items: center;
  background: #000;
  cursor: pointer;
}

img.google-pay-icons {
  /* width: 51px;
    vertical-align: bottom;
    position: relative;
    top: 2px; */
}

.gpay-button {
  padding: 0 !important;
  /* width: 40% !important;
  margin-right: 2rem !important; */
}

.amount-popup-overlay .google-pay-button-container {
  /* position: absolute;
    width: 142px !important;
    right: 7px;
    top: 0; */
}

/* apple pay button */

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    cursor: pointer;
    width: 100%;
    margin-left: 0rem;
    border-radius: 40px !important;
  }

  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }

  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }

  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    cursor: pointer;
    display: inline-block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 40px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 40px;
    width: 100%;
  }

  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }

  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }

  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black;
  }
}

.apple-pay-span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  height: 45px !important;
}

.apple-pay-span p {
  color: white;
  letter-spacing: 1px;
}

.applepaylogo {
  width: 20px;
  margin-bottom: 4px;
}

.amount-popup-overlay {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 9999999;
  overflow: auto;
  top: 0;
  bottom: 0;
}

.amount-popup {
  position: relative;
  z-index: 10000000;
  background: #323232;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: 0 auto;
  width: 460px;
  color: #fff;
  left: auto;
  top: 30%;
  padding: 1em 2em 2em 2em;
  border: 2px solid #555;
  border-radius: 8px;
}

img.pay-icons {
  width: 75px;
}

button.cancel-btn {
  position: absolute;
  right: 25px;
  top: 20px;
  padding: 6px 15px;
  border-radius: 6px;
  border: 0;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.amount-popup h1 {
  font-size: 1.6em;
  text-align: center;
  font-weight: 500;
}

.amount-popup input {
  padding: 7px 150px 7px 15px;
  border-radius: 6px;
  border: 0;
  height: 40px;
  width: 100%;
}

.wrap-inputs {
  position: relative;
}

button.continue-btn {
  position: absolute;
  right: -1px;
  top: 0px;
  height: 40px;
  background: #535351;
  color: #fff;
  border: 0;
  text-transform: uppercase;
  border-radius: 0px 6px 6px 0px;
  padding: 5px 15px;
  font-weight: 600;
  letter-spacing: 0.03em;
  cursor: pointer;
}

img.po-logo {
  width: 10em;
}

.mobile-view .mobile-view-wrap {
  height: 170px;
  overflow: hidden;
  overflow-y: auto;
}

.flex-row {
  display: flex;
  gap: 0.5rem;
}

.info-icon {
  border-radius: 50%;
  background: none;
  font-size: 10px;
  position: relative;
  height: 15px;
  width: 15px;
  background-color: rgb(145, 144, 144);
  border: none;
  cursor: pointer;
  /* top: 11px; */
}

.info-wrap {
  display: inline-block;
  margin: 0 0 0 7px;
}

span.hidden-text {
  position: relative;
  display: block;
  margin: 0 0 7px 0;
  font-weight: 400;
}

.info-icon span {
  position: absolute;
  top: 1px;
  left: 6px;
  color: white;
  font-size: 0.7rem;
  font-weight: 700;
}

span.hidden-span {
  display: none;
}

.info-icon:hover span.hidden-span {
  position: absolute;
  left: 20px;
  font-weight: 300;
  min-width: 239px;
  display: block;
  background: #ffffff;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border: 1px solid #ccc;
  text-align: left;
  padding: 4px 15px 4px 12px;
  border-radius: 6px;
  top: -5px;
  font-size: 11px;
  width: max-content;
  z-index: 99;
  text-transform: none;
}

span.hidden-text {
  position: relative;
  font-weight: 400;
}

button.gpay-card-info-container.black.pay {
  height: 44px !important;
  border-radius: 40px !important;
}

#gpay-button-online-api-id {
  outline: 0 !important;
  border-radius: 40px !important;
  min-width: auto !important;
  top: 3px;
  box-shadow: 0 0 0 !important;
}


/*============ Responsive css ===============*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .cardcolumn .inputgroup:nth-child(7),
  .cardcolumn .inputgroup:nth-child(8) {
    width: 30% !important;
  }
}

@media only screen and (max-width: 479px) {
  .cardcolumn .inputgroup {
    width: 100%;
  }

  .product-lists {
    padding: 0;
  }

  .cardcolumn .inputgroup:nth-child(3) {
    width: 100% !important;
  }

  .product-details {
    width: 73%;
    margin: 0 0 0 22px;
  }

  .cardcolumn .inputgroup:nth-child(2),
  .cardcolumn .inputgroup:nth-child(4),
  .cardcolumn .inputgroup:nth-child(5) {
    width: 100% !important;
  }

  .cardcolumn .inputgroup:nth-child(5),
  .cardcolumn .inputgroup:nth-child(7),
  .cardcolumn .inputgroup:nth-child(8),
  .cardcolumn .inputgroup:nth-child(11) {
    margin-right: 0;
    margin-left: 0%;
  }

  .cardcolumn .inputgroup:nth-child(6),
  .cardcolumn .inputgroup:nth-child(7),
  .cardcolumn .inputgroup:nth-child(8),
  .cardcolumn .inputgroup:nth-child(9),
  .cardcolumn .inputgroup:nth-child(10),
  .cardcolumn .inputgroup:nth-child(11) {
    width: 100% !important;
  }

  img.card-icons {
    width: 30px;
  }
}

.redirect-text {
  font-size: 12px;
  color: gray;
  letter-spacing: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1370px) {
  .layout {
    padding: 11rem 2rem 1.5rem !important;
  }

  .layout.layout-cards.vertical-alignment {
    padding: 14rem 2.5rem 1.5rem !important;
  }

  .inputgroup label {
    font-size: 13px !important;
  }
}

@media (min-height: 720px) and (max-height: 800px) and (min-width: 1280px) and (max-width: 1440px) {
  .main-container {
    margin: 20px 0 !important;
  }

  .main-sep {
    top: 10.3em !important;
  }

  .wallet-buttons.main-sep.hide-form {
    top: 18em !important;
  }

  img.po-logo {
    width: 9em !important;
    margin: 0 0 -8px 0 !important;
  }
}

.flex-section {
  display: flex;
  flex-direction: row-reverse;
  gap: 2em;
}

/*============ Responsive css ===============*/
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  /* .boxwrapper, .cont2 {
    left: 40%;
    width: 32rem;
  }  */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  /* .boxwrapper, .cont2 {
    left: 33%;
    width: 32rem;
  }  */
  .wrapper-div {
    width: 97% !important;
    margin: 0 auto !important;
  }

  .form-list-wrap .boxwrapper {
    padding: 1.2em 1.2rem 1.2rem;
  }

  .form-list-wrap .boxwrapper form.payment-card-form {
    padding-top: 0px;
  }

  .credit-icon {
    top: 9px;
    width: 1.2em;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .pay-info h5 span {
    text-align: left;
    display: block;
    float: none;
    width: 100%;
    margin-top: 8px;
  }

  h6.product-price.p-total-price {
    right: -15px;
    top: 15px;
  }

  .background-wrapper {
    display: block;
    height: 100vh;
  }

  .background-wrapper .payment-container {
    align-items: start;
  }

  img.background-image {
    display: none;
  }

  .boxwrapper {
    width: 100%;
    /* -webkit-transform: translate(-50%, 80px);
    -moz-transform: translate(-50%, 80px);
    transform: translate(-50%, 80px); */
    padding: 1em;
    position: relative;
    /* left: 50%; */
  }

  .cont2,
  .combo-cont {
    width: 95%;
  }

  .logo-box {
    margin: 0 auto 12px;
    transform: none;
    left: auto;
    position: relative;
  }

  .select-country {
    margin: 0 0 2px 0;
  }

  .show-wrap-length.error-footer {
    width: 100% !important;
  }

  .mobile-view .mobile-view-wrap::-webkit-scrollbar-track,
  .mobile-view .mobile-view-wrap::-webkit-scrollbar-thumb {
    display: block;
  }

  .flex-section {
    flex-direction: column;
  }

  .form-div {
    width: 100% !important;
  }

  .susbcriptionPopup {
    width: 100% !important;
    font-size: 12px !important;
  }

  .inputgroup input {
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .info-icon:hover span.hidden-span {
    width: 15rem;
    left: -5rem;
    top: 1.5rem;
  }

  .pay-info h5 span {
    text-align: left;
    display: block;
    float: none;
    width: 100%;
    margin-top: 8px;
  }

  h6.product-price.p-total-price {
    right: -15px;
    top: 15px;
  }

  .susbcriptionPopup {
    width: 100% !important;
    font-size: 12px !important;
  }

  .form-div {
    width: 100% !important;
  }

  .flex-section {
    flex-direction: column;
  }

  .wrappers {
    padding: 5px !important;
  }

  .amount-popup {
    width: 95%;
  }

  .apple-pay-button-div {
    top: 7px !important;
  }

  .wallet-apple-google-pay-buttons {
    margin: 0 0 -10px 0;
  }

  .background-wrapper {
    display: block;
    height: 100vh;
  }

  .background-wrapper .payment-container {
    align-items: start;
  }

  img.background-image {
    display: none;
  }

  .boxwrapper,
  .cont2,
  .combo-cont {
    width: 100%;
    /* -webkit-transform: translate(-50%, 80px);
    -moz-transform: translate(-50%, 80px);
    transform: translate(-50%, 80px); */
    padding: 1em;
    /* left: 50%; */
    box-shadow: 0 0 0;
  }

  .logo-box {
    margin: 0 auto;
    transform: none;
    left: auto;
    position: relative;
  }

  .logo-div {
    width: 76%;
  }

  h5.company-name {
    font-size: 14px;
    font-weight: 600;
  }

  p.company-address {
    font-size: 11px;
  }

  .payment-box {
    padding: 3em 1.5em;
  }

  h4.payment-status {
    font-size: 1.5em;
  }

  .oreder-overview h4 span {
    font-size: 1rem;
  }

  .oreder-overview h4 span strong {
    font-size: 0.8rem;
  }

  .product-details h5 {
    font-size: 0.8em;
    margin: 0 10px 0 0;
  }

  .product-details h6 {
    font-size: 0.8em;
    margin: 0 10px 0 0;
  }

  .pay-info h5 {
    font-size: 0.8em;
  }

  h6.product-price {
    min-width: 13%;
  }

  button.open-product-deatail {
    top: -111px;
    display: none;
  }

  .product-pay-details {
    right: 5px;
    z-index: 999;
    display: none;
  }

  button.pop-up-remove-btn {
    display: none;
  }

  .alsolute-cards {
    bottom: 103px;
  }

  img.error-img-icon {
    width: 100%;
  }

  .payment-container.error-container {
    background: transparent;
    display: block;
  }

  .countdown-timer {
    position: absolute;
    bottom: 10%;
    font-size: 0.9rem;
    text-align: center;
    width: 100%;
    top: auto;
  }

  .wrapper-div {
    position: relative;
    width: 100% !important;
    margin: 0 !important;
  }

  .wrapper-div .MuiGrid-grid-xs-5,
  .wrapper-div .MuiGrid-grid-xs-7,
  .wrapper-div .MuiGrid-grid-xs-6 {
    padding: 0 !important;
    max-width: 100%;
    flex-basis: 100%;
    margin: 0;
  }

  .wrapper-div .logo-box {
    left: auto;
    -webkit-transform: translate(0%, 37px);
    transform: translate(0%, 37px);
  }

  .show-wrap-length,
  .footer-wrap {
    width: 100%;
    bottom: 0;
  }

  .form-list-without-wrap .boxwrapper,
  .form-list-wrap .boxwrapper {
    width: 100%;
    padding: 1.2em 0 1.2rem;
    min-height: auto;
  }

  /* .product-lists {
    padding: 3.7em 1.2rem 2rem 2.2rem;
  } */

  .select-country {
    margin: 0 0 2px 0;
  }

  .show-wrap-length.error-footer {
    width: 100% !important;
  }

  .tcenter {
    margin: 0 auto 20px;
  }

  .mobile-view .mobile-view-wrap::-webkit-scrollbar-track,
  .mobile-view .mobile-view-wrap::-webkit-scrollbar-thumb {
    display: block;
  }

  .inputgroup input {
    font-size: 14px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input:disabled {
    background-color: #f2f2f2;
  }
}
body {
  background: url(../../components/assets/background/hpp-bg.jpg);
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
}

.layout {
  width: 100%;
  height: auto;
  border: 1px solid #eae8e8;
  border-radius: 30px;
  padding: 11rem 2.5rem 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  box-shadow: 0px 20px 30px 0px #0000000d;
  background: #f9f9f9;
}

.layout.layout-cards.remove-padding {
  padding-top: 4rem !important;
  top: 3px;
}

#country svg {
  color: #d75323;
}

.inputgroup input {
  border-radius: 50px;
  height: 48px;
  padding: 12px 30px 12px 20px;
  display: block;
  border: 1px solid #dedede;
  width: 100%;
}

.inputgroup label {
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin: 0 0 5px 0;
  display: block;
}

.inputgroup {
  margin: 0 2% 2% 0;
  position: relative;
  width: 48% !important;
  display: inline-block;
}

.amount .inputgroup:nth-child(1) {
  width: 100% !important;
}

/* .layout-cards .inputgroup:nth-child(5) {
    width: 100% !important;
} */
/* .layout-cards .inputgroup:nth-child(4) {
  margin-right: 0;
} */
.layout-cards .inputgroup:nth-child(6) {
  margin: 0 2% 0 2%;
  width: 24% !important;
}

.layout-cards .inputgroup:nth-child(7) {
  /* margin: 0; */
  width: 22% !important;
}

.layout-cards .inputgroup:nth-child(5) {
  margin: 0 0 2% 0;
}

.layout-cards .inputgroup:nth-child(10),
.layout-cards .inputgroup:nth-child(12) {
  margin-right: 0 !important;
}

.layout.layout-cards .inputgroup:first-child {
  width: 98% !important;
}

.layout.layout-cards .country-postcode .inputgroup {
  width: 48% !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded {
  background: transparent;
  padding: 0 0;
  box-shadow: 0 0 0;
  margin: 0;
}

.MuiAccordionDetails-root {
  padding: 0 0 !important;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 0 0 !important;
}

.MuiAccordionSummary-expandIconWrapper {
  position: relative;
  right: 14px !important;
}

/* .layout-cards .inputgroup:nth-child(14),
.layout-cards .inputgroup:nth-child(16),
.layout-cards .inputgroup:nth-child(18) {
  margin-right: 0 !important;
} */
div#country input {
  height: 36px;
}

.iw-50 {
  width: 100%;
  display: inline-block;
}

.iw-100 {
  width: 100% !important;
}

.layout img.credit-icon {
  right: 33px;
  top: 37px;
  left: auto;
  width: 22px;
}

.card-images {
  position: absolute;
  right: 320px;
  top: 378px;
}

.card-images.new-card-images {
  top: 283px;
}

.layout h5.formLabel {
  font-weight: 700;
  font-size: 14px;
  /* line-height: 21px; */
  color: #b7b7b7;
  margin-top: 1.2rem;
  /* border-bottom: 1px solid #dadada; */
  padding: 0 0 10px 0;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.layout.layout-cards .billing-section .inputgroup:first-child {
  width: 48% !important;
}

.other-payment-methods-container-heading p {
  font-size: 15px;
  color: #8f8f8f;
  line-height: 21px;
  letter-spacing: -1px;
}

.layout.layout-cards .inputgroup:nth-child(4) span.inputcheck {
  top: 12px;
}

.layout.layout-cards .billing-section .inputgroup:nth-child(4) span.inputcheck {
  top: 35px;
}

.total-price h5 span {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: right;
}

.total-price h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.main-container .language-select {
  position: absolute;
  top: 16px;
  z-index: 99;
}

.layout.layout-cards .inputgroup:first-child input.add-margin-amount {
  margin: 0 0 0px 0;
}

.main-sep {
  position: absolute;
  width: 35rem;
  top: 11.3em !important;
  display: block;
  margin: 0 3%;
  z-index: 9;
  bottom: auto !important;
}

.full-width-button {
  width: 100% !important;
  margin: 0;
}

.pay-seperator {
  padding: 5px 0;
}

.wallet-buttons.main-sep.hide-form {
  height: 120px;
  top: 20rem !important;
}

/* .layout-billing .inputgroup:last-child{
  margin-bottom: 0;
} */

/*  responsive for laptops  */

@media only screen and (min-width: 1029px) and (max-width: 1366px) {
  .pay-seperator hr {
    width: 30% !important;
  }
  .main-container {
    width: 76rem !important;
  }

  .main-sep {
    width: 29rem !important;
    top: 12rem !important;
    bottom: auto !important;
    margin: 0 8%;
  }

  .product-list-wrap {
    width: 32rem !important;
  }

  .card-images {
    left: 200px;
  }

  .main-container {
    margin: 25px 0;
  }
}


@media only screen and (min-width: 767px) and (max-width: 1028px) {
  .pay-seperator hr {
    width: 30% !important;
  }

  .wrappers {
    display: block !important;
    padding: 0 20px;
  }

  .main-container {
    width: 100% !important;
  }

  .main-sep {
    top: 12rem !important;
    margin: 0 8%;
    bottom: auto !important;
  }

  .main-sep.with-product {
    top: 27em;
  }

  /* .wallet-apple-google-pay-buttons{
    display: block !important;
}
.wallet-apple-google-pay-buttons .w-50 {
    width: 100% !important;
    display: block;
    margin: 0 0 10px 0;
} */

  .additional-product .product-list-wrap {
    display: block;
    margin: 20px 0 0 0 !important;
    width: 100%;
  }

  .card-images {
    right: 365px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {

  .show-only-apple-pay-and-google-pay-button {
    margin-bottom: 0 !important;
  }

  .w-40rem {
    width: 90%;
  }

  .wallet-buttons.main-sep.pbl-sep {
    top: 35em !important;
  }

  .mobile-view-wrap {
    height: 150px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .main-sep {
    width: 84%;
    top: 12rem !important;
    margin: 0 8%;
    bottom: auto !important;
  }

  .wrappers {
    display: block !important;
    padding: 0 20px;
  }

  .other-card-image {
    width: 47px;
  }

  .main-container {
    width: 100% !important;
  }

  .wallet-apple-google-pay-buttons .w-50 {
    width: 100% !important;
    display: block;
    margin: 0 0 10px 0;
  }

  .additional-product .product-list-wrap {
    display: block;
    margin: 20px 0 0 0 !important;
    width: 100%;
    padding: 2.5rem 1.5rem 1rem !important;
  }

  .inputgroup {
    width: 100% !important;
    margin: 0 2% 20px 0 !important;
  }

  .layout {
    padding: 10rem 1.5rem 2rem;
  }

  .layout-cards .inputgroup:nth-child(5),
  .layout-cards .inputgroup:nth-child(14) {
    margin: 0 0 20px !important;
  }

  .layout-cards .inputgroup:nth-child(6),
  .layout-cards .inputgroup:nth-child(7) {
    width: 100% !important;
    margin: 0 0 20px !important;
  }

  .card-images {
    top: 470px;
    left: auto;
    right: 20px;
  }

  .card-images.new-card-images {
    top: 373px;
  }

  .wallet-apple-google-pay-buttons div#apple-pay-row {
    margin: 0 0 8px 0;
  }

  .apple-pay-button-div {
    top: 7px;
  }

  .main-sep.with-product {
    top: 34em;
  }

  /* .layout.layout-cards .inputgroup:first-child input.add-margin-amount {
    margin: 0 0 131px 0;
} */
  .layout.layout-cards .inputgroup:first-child input {
    background-position-x: 89% !important;
    background-position-y: 13px;
  }

  .pay-seperator p {
    width: 61% !important;
  }

  .layout.layout-cards .country-postcode .inputgroup,
  .layout.layout-cards .billing-section .inputgroup:first-child {
    width: 100% !important;
  }
}

@media only screen and (max-width: 479px) {

  .show-only-apple-pay-and-google-pay-button {
    margin-bottom: 0 !important;
  }

  .main-sep.vertical-alignment {
    width: 84% !important;
  }

  .pay-seperator hr {
    width: 30% !important;
  }

  .w-40rem {
    width: 90%;
  }

  .wallet-buttons.main-sep.pbl-sep {
    top: 33.5em !important;
  }

  .mobile-view-wrap {
    height: 150px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .main-sep {
    width: 84%;
    top: 12rem !important;
    margin: 0 8%;
    bottom: auto !important;
  }

  .layout.layout-cards .country-postcode .inputgroup,
  .layout.layout-cards .billing-section .inputgroup:first-child {
    width: 100% !important;
  }

  .wrappers {
    display: block !important;
    padding: 0 20px;
  }

  .other-card-image {
    width: 47px;
  }

  .main-container {
    width: 100% !important;
  }

  .wallet-apple-google-pay-buttons .w-50 {
    width: 100% !important;
    display: block;
    margin: 0 0 10px 0;
  }

  .additional-product .product-list-wrap {
    display: block;
    margin: 20px 0 0 0 !important;
    width: 100%;
    padding: 2.5rem 1.5rem 1rem !important;
  }

  .inputgroup {
    width: 100% !important;
    margin: 0 2% 20px 0 !important;
  }

  .layout {
    padding: 10rem 1.5rem 2rem;
  }

  .layout-cards .inputgroup:nth-child(5),
  .layout-cards .inputgroup:nth-child(14) {
    margin: 0 0 20px !important;
  }

  .layout-cards .inputgroup:nth-child(6),
  .layout-cards .inputgroup:nth-child(7) {
    width: 100% !important;
    margin: 0 0 20px !important;
  }

  .card-images {
    top: 470px;
    left: auto;
    right: 20px;
  }

  .card-images.new-card-images {
    top: 373px;
  }

  .wallet-apple-google-pay-buttons div#apple-pay-row {
    margin: 0 0 8px 0;
  }

  .apple-pay-button-div {
    top: 7px;
  }

  .main-sep.with-product {
    top: 33em;
  }

  /* .layout.layout-cards .inputgroup:first-child input.add-margin-amount {
    margin: 0 0 131px 0;
} */
  .layout.layout-cards .inputgroup:first-child input {
    background-position-x: 89% !important;
    background-position-y: 13px;
  }

  .pay-seperator p {
    width: 61% !important;
  }

  .main-container.overflow-visible {
    margin-bottom: 100px;
  }

}

/* .country-postcode .inputgroup:last-child {
  margin: 0% !important;
} */

/* ibec  apple pay google pay alignment */

.main-sep.vertical-alignment {
  /* width: 36.5rem; */
}

.vertical-alignment .wallet-apple-google-pay-buttons {
  flex-direction: column;
  gap: 5px;
}

.vertical-alignment .wallet-apple-google-pay-buttons .apple-pay-button-div {
  width: 100%;
}

.vertical-alignment .wallet-apple-google-pay-buttons .google-pay-button {
  width: 100%;
  margin: 0;
}

.layout.layout-cards.vertical-alignment {
  padding: 18rem 2.5rem 1.5rem;
}
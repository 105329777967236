.iframe-home::before {
    background: #fefefe;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    content: "";
    height: 100vh;
}

.iframe-home {
    width: 40rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.iframe-home .layout.layout-cards.remove-padding {
    padding: 2.5rem !important;
    background: #fff;
}

.iframe-home .inputgroup input {
    border-radius: 6px;
    height: 38px;
    padding: 8px 30px 8px 20px;
}

.iframe-home div#country input {
    height: 24px;
}

.iframe-home div#country div {
    border-radius: 6px !important;
}

.iframe-home .layout.layout-cards .inputgroup:first-child input {
    background-position-x: 94%;
    background-position-y: 8px;
    background-size: 18px;
}

.iframe-home .layout.layout-cards .inputgroup:nth-child(4) span.inputcheck {
    top: 11px;
}

.iframe-home .inputcheck {
    top: 31px;
}

.iframe-home .action-buttons button {
    border-radius: 6px !important;
    padding: 10px 20px !important;
    min-height: 38px;
    height: 38px;
}

.iframe-home .layout h5.formLabel {
    color: #383838;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.main-container.overflow-visible {
    overflow: visible;
}

/* for only google pay and apple pay View */

.main-container.overflow-visible .wallet-buttons.main-sep {
    position: absolute;
    bottom: 160px;
    top: auto;
}

.show-only-apple-pay-and-google-pay-button {
    background: #f9f9f9;
    border: 1px solid #eae8e8;
    border-radius: 30px;
    box-shadow: 0 20px 30px 0 #0000000d;
    height: auto;
    margin-bottom: 1.5rem;
    padding: 5rem 2.5rem 17rem;
    position: relative;
    width: 100%;
    margin-top: 22px;
}

.gpay-appay-amount-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

span.gpay-appay-amount-div-label {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

span.gpay-appay-amount-div-price {
    letter-spacing: 1px;
    font-weight: 600;
    color: #555;
    font-size: 1.5rem;
}
.apple-pay-button.apple-pay-button-black.with-white-inactive-text {
    color: #fff;
    padding: 10px 0;
    text-align: center;
    cursor: not-allowed;
}
.gpay-appay-amount-div img.currency-img-icon {
    right: 12px;
    position: relative;
}
.gpay-appay-amount-div span.inputcheck {
    top: 9px;
}
.show-only-apple-pay-and-google-pay-button label {
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin: 0 0 5px 0;
    display: block;
}
/*============ Responsive css ===============*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {}

@media only screen and (max-width: 479px) {
    .iframe-home {
        width: 90%;
        margin-top: 1em;
    }
    h4.payment-status{
        font-size:1.2rem !important}
        hr.dotted {
            margin: 1.5rem 0 1.5rem;
            }
}
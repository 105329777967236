.subscription-details {
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem 0 0;
  gap: 5px;
}
.flex-1 {
  width: 45%;
}
.flex-1:nth-child(even) {
  text-align: end;
}
strong.plan-heading {
  font-weight: 500;
  color: #646464;
}
.subscription-flex{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.subscription-flex:last-child {
  display: block;
}
.subscription-flex:last-child strong.plan-heading {
  display: block;
  margin: 0 0 6px 0;
}

.subscription-flex:last-child span.subscription-value {
  font-size: .85rem;
  color: #979797;
  text-align: left !important;
}

.wrappers {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    gap: 4em;
}

.main-container {
    width: 80rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 55px 0;
    position: relative;
}

.w-50 {
    width: 49%;
}

.wallet-apple-google-pay-buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.w-50:last-child {
    margin-left: 2%;
}

.gpay-button {
    width: 48% !important;
}

.google-pay-button-container {
    width: 100% !important;
}

.gpay-button.black.pay {
    background-size: 35%;
}

.apple-pay-button-div {
    position: relative;
    top: 2px;
    width: 49%;
    border-radius: 40px;
}

.total-price {
    border-top: 1px solid #dadada;
}

img.google-pay-icons {
    width: 56px;
    padding: 10px 0 8px;
    vertical-align: middle;
}

.layout.layout-cards .inputgroup:first-child input {
    background-position-x: 94%;
    background-position-y: 14px;
}

.form-div {
    width: 40rem;
    margin: 0 auto;
}

.ibec-google-pay-apple-pay-title-text {
    margin-bottom: 0px;
}
.ibec-google-pay-apple-pay-title-text.mb-25 {
    margin-bottom: 25px;
}
.currency-img-icon {
    width: 21px;
    top:1px;
}
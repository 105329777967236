.payment-cards {
    position: absolute;
    right: 0;
    top: -2px;
}

.susbcriptionPopup {
    position: relative;
    background: #ffffff;
    margin: 15px 0 30px !important;
    padding: 2.5rem 2.5rem 2.5rem !important;
    border-radius: 30px !important;
    top: 0px;
    box-shadow: 0px 20px 30px 0px #0000000d;
    z-index: 1;
    display: flex;
    width: 32rem;
    flex-direction: column;
}

.center {
    display: flex;
    justify-content: center;
}

.center.w-50 {
    width: 50%;
    margin: 0 auto;
}

.pci-logo-div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.pci-logo {
    width: 150px;
    height: 100px;
}

.gpayApplePayTitle {
    padding-top: 14.5rem !important;
}

.gpayApplePayTitle.applePaySessionAvailable {
    padding-top: 20rem !important;
}
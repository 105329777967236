.news-letter-div {
  display: flex;
  flex-direction: column;
  width: 85%;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin: 0 auto;
}
.newsletter-input {
  position: relative;
  width: 100%;
}
.newsletter-input input {
  width: 100%;
  border-radius: 50px;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 9rem;
}
.newsletter-input button:disabled{
    background: gray;
    cursor: not-allowed;
} 
.newsletter-input button {
  position: absolute;
  right: 0;
  /* border-radius: 50%; */
  height: 2.6rem;
  padding: 0.2rem;
  right: 0.3rem;
  top: 0.2rem;
  width: 8rem;
  height: 2.5rem;
  font-size: 1rem;
  background: linear-gradient(92.03deg, #fcc954 0%, #e56523 100%);
  border: none;
  cursor: pointer;
  border: 2px solid;
  /* border-top-right-radius: 50px; */
  border-radius: 50px;
  color: white;
}
.email-sent-successfully {
  color: gray;
}
.error-sendemail {
  color: red;
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
}
.w-40rem {
  width: 40rem;
  margin: 0 auto;
}

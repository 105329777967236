.twocolumn {
  position: relative;
}

.cardcolumn {
  position: relative;
}

.twocolumn .inputgroup {
  width: 48%;
  padding: 0 0;
  margin: 0 1% 20px;
  position: relative;
  display: inline-block;
}

.cardcolumn .inputgroup {
  width: 48%;
  padding: 0 0;
  margin: 0 2% 12px 0;
  position: relative;
  display: inline-block;
}

.cardcolumn .inputgroup:nth-child(2) {
  width: 100% !important;
}

.cardcolumn .inputgroup:nth-child(4),
.cardcolumn .inputgroup:nth-child(5),
.cardcolumn .inputgroup:nth-child(6),
.cardcolumn .inputgroup:nth-child(10),
.cardcolumn .inputgroup:nth-child(11) {
  width: 48% !important;
}

.cardcolumn .inputgroup:nth-child(5),
.cardcolumn .inputgroup:nth-child(7),
.cardcolumn .inputgroup:nth-child(8),
.cardcolumn .inputgroup:nth-child(11) {
  margin-right: 0;
  margin-left: 2%;
}

.cardcolumn .inputgroup:nth-child(7),
.cardcolumn .inputgroup:nth-child(8) {
  width: 23% !important;
}

/* .cardcolumn .inputgroup:nth-child(7) {
  width: 28% !important;
} */

.cardcolumn .formcontrol {
  width: 100%;
  padding: 5px 30px 4px 15px;
  color: black;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 36px;
}

.cardcolumn .inputgroup:nth-child(2) .formcontrol,
.cardcolumn .inputgroup:nth-child(6) .formcontrol,
.cardcolumn .inputgroup:nth-child(7) .formcontrol {
  padding: 5px 30px 4px 40px;
}

.inputcheck {
  position: absolute;
  right: 0;
  top: 5px;
  /* border-left: 1px solid #ccc; */
  padding: 2px 8px;
  width: 36px;
}

.inputcheck svg {
  position: relative;
  top: 2px;
}

.submitbtn {
  padding: 10px 20px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  background: #606060;
  /* background: -webkit-linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);
  background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6); */
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.9em;
  letter-spacing: 0.3px;
  transition: all 0.7s ease 0s;
  width: 49%;
  margin: 0 0% 0 1%;
  border: 0;
  cursor: pointer;
}

.submitbtn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.cancelbtn {
 padding: 12px 20px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  background: #e5e5e5;
  color: #606060;
  /* text-transform: uppercase; */
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.3px;
  transition: all 0.7s ease 0s;
  width: 49%;
  margin: 0 1% 0 0%;
  border: 0;
  border-radius: 50px !important;
  cursor: pointer;
}

.submitbtn:hover,
.submitbtn:focus {
  background: #494747;
  /* background: -webkit-linear-gradient(to right, #667db6, #667db6, #0082c8, #0082c8);
  background: linear-gradient(to right, #667db6, #667db6, #0082c8, #0082c8); */
}

.cancelbtn:hover,
.cancelbtn:focus {
  background: rgb(203, 199, 199);
}

.formLabel {
  margin: 0 0 8px 0px;
  padding: 0;
  font-weight: 500;
  text-transform: uppercase;
  color: #3a3a3a;
}



/*============ Responsive css ===============*/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .cardcolumn .inputgroup:nth-child(7),
  .cardcolumn .inputgroup:nth-child(8) {
    width: 30% !important;
  }
}

@media only screen and (max-width: 479px) {
  .cardcolumn .inputgroup {
    width: 100%;
  }

  .cardcolumn .inputgroup:nth-child(3) {
    width: 100% !important;
  }

  .cardcolumn .inputgroup:nth-child(2),
  .cardcolumn .inputgroup:nth-child(4),
  .cardcolumn .inputgroup:nth-child(5) {
    width: 100% !important;
  }

  .cardcolumn .inputgroup:nth-child(5),
  .cardcolumn .inputgroup:nth-child(7),
  .cardcolumn .inputgroup:nth-child(8),
  .cardcolumn .inputgroup:nth-child(11) {
    margin-right: 0;
    margin-left: 0%;
  }

  .cardcolumn .inputgroup:nth-child(6),
  .cardcolumn .inputgroup:nth-child(7),
  .cardcolumn .inputgroup:nth-child(8),
  .cardcolumn .inputgroup:nth-child(9),
  .cardcolumn .inputgroup:nth-child(10),
  .cardcolumn .inputgroup:nth-child(11) {
    width: 100% !important;
  }

  img.card-icons {
    width: 30px;
  }
}
